exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wohnungen-doppelzimmer-js": () => import("./../../../src/pages/wohnungen/doppelzimmer.js" /* webpackChunkName: "component---src-pages-wohnungen-doppelzimmer-js" */),
  "component---src-pages-wohnungen-ferienhaus-js": () => import("./../../../src/pages/wohnungen/ferienhaus.js" /* webpackChunkName: "component---src-pages-wohnungen-ferienhaus-js" */),
  "component---src-pages-wohnungen-wohnung-1-js": () => import("./../../../src/pages/wohnungen/wohnung1.js" /* webpackChunkName: "component---src-pages-wohnungen-wohnung-1-js" */),
  "component---src-pages-wohnungen-wohnung-2-js": () => import("./../../../src/pages/wohnungen/wohnung2.js" /* webpackChunkName: "component---src-pages-wohnungen-wohnung-2-js" */)
}

